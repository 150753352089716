///<reference path="./stubs.d.ts" />
/* eslint-disable */
import { useState, useEffect } from "react";
import "./styles.css";
import Graph from "react-graph-vis";

type ATerm =
    | {
          type: string;
          args: ATerm[];
      }
    | string
    | number
    | ATerm[];

function parseAterm(term: string) {
    term = term.replace(/([\s[(])\(/g, "$1Tuple(");

    const createProxyFor = (namePrefix: any) => ({
        has(recv: any, name: any) {
            return typeof name !== "symbol";
        },
        get(recv: any, name: any): any {
            if (name === "eval") return eval;
            if (name === "term") return term;
            if (typeof name === "symbol") return Reflect.get(recv, name);

            return new Proxy(
                (...args: any[]) => ({
                    type: namePrefix + name,
                    args
                }),
                createProxyFor(namePrefix + name + ".")
            );
        }
    });

    return Function(
        `createProxyFor`,
        `term`,
        `
        const prox = new Proxy({}, createProxyFor(""));
        return eval(\`with (prox) \${term}\`);
    `
    )(createProxyFor, term);
}

const prettyPrintATerm = (x: ATerm, depth = 0): string => {
    const spaces = Array(depth).fill(" ").join("");
    if (typeof x === "string" || typeof x === "number") return spaces + JSON.stringify(x);

    if (Array.isArray(x)) {
        if (!x.length) return `${spaces}[]`;
        if (x.length === 1) return `${spaces}[${prettyPrintATerm(x[0], depth).trim()}]`;

        return `${spaces}[\n${x.map((x) => prettyPrintATerm(x, depth + 2)).join(",\n")}\n${spaces}]`;
    }

    const formatArgs = (args: ATerm[]) => {
        if (!args.length) return "()";
        if (args.length === 1) return "(" + prettyPrintATerm(args[0], depth).trim() + ")";

        return `(\n${args.map((x) => prettyPrintATerm(x, depth + 2)).join(",\n")}\n${spaces})`;
    };

    if (x.type === "Tuple") return spaces + formatArgs(x.args);
    if (x.type === "Scope") return spaces + "#" + x.args[1];

    return `${spaces}${x.type}${formatArgs(x.args)}`;
};

const printATerm = (x: ATerm): string => {
    if (typeof x === "string" || typeof x === "number") return JSON.stringify(x);
    if (Array.isArray(x)) return `[${x.map(printATerm).join(", ")}]`;
    if (x.type === "Tuple") return `(${x.args.map(printATerm).join(", ")})`;
    return `${x.type}(${x.args.map(printATerm).join(", ")})`;
};

function isCons(a: ATerm, name: string): boolean {
    return typeof a === "object" && !Array.isArray(a) && a.type === name;
}

function findScopeReferences(x: ATerm): string[] {
    if (typeof x === "string" || typeof x === "number") return [];
    if (Array.isArray(x)) return ([] as string[]).concat(...x.map(findScopeReferences));
    if (x.type === "Scope") return [x.args.join("-")];
    return ([] as string[]).concat(...x.args.map(findScopeReferences));
}

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

function ScopeGraphNetwork({ network, mode }: { network: ATerm; mode: string }) {
    const { height, width } = useWindowSize();

    if (!isCons(network, "ScopeGraph")) {
        return <p>Invalid scopegraph: top level constructor is not a ScopeGraph.</p>;
    }

    // return true if this scope contains children instead of a value
    const isScopeNode = (scopeName: ATerm, scopeValue: ATerm) => {
        return (
            isCons(scopeName, "Scope") &&
            isCons(scopeValue, "Some") &&
            printATerm(scopeName) === printATerm((scopeValue as any).args[0])
        );
    };

    const getEdgeName = (edgeType: ATerm): string | null => {
        if (!isCons(edgeType, "Label")) return null;

        const labelName = ((edgeType as any).args[0] as string).split("!")[1];

        // only use single sized edges
        if (labelName.length !== 1) {
            return null;
        }

        return labelName;
    };

    const nodesById = new Map<string, any>();

    const nodes = (network as any).args[0]
        .map((x: any, i: number) => {
            const [scopeName, scopeValue, scopeContents] = x.args;
            const id = scopeName.args.join("-");
            if (!isCons(scopeValue, "Some")) return null;

            if (!isScopeNode(scopeName, scopeValue)) {
                const value = prettyPrintATerm(scopeValue.args[0]);
                return {
                    id,
                    label: value,
                    shape: "box",
                    font: { face: "Monospace", align: "left" },
                    width: 200,
                    height: 200
                };
            }

            const node: any = { id, label: "#" + scopeName.args[1] };
            if (mode === "levels") node.level = i;

            return node;
        })
        .filter((x: any) => x);
    for (const node of nodes) nodesById.set(node.id, node);

    const edges = [].concat(
        ...(network as any).args[0].map((x: any) => {
            const [scopeName, scopeValue, scopeContents] = x.args;
            if (!isScopeNode(scopeName, scopeValue)) return [];

            const items = [];
            for (const content of scopeContents) {
                const [edgeType, values] = content.args;
                const labelName = getEdgeName(edgeType);

                if (!labelName) {
                    const relationName = isCons(edgeType, "Label")
                        ? (edgeType as any).args[0].split("!")[1]
                        : printATerm(edgeType);

                    items.push(
                        ...values.map((v: any) => {
                            if (mode === "levels")
                                nodesById.get(v.args.join("-"))!.level = nodesById.get(scopeName.args.join("-"))!.level;

                            return {
                                from: scopeName.args.join("-"),
                                to: v.args.join("-"),
                                label: relationName,
                                arrows: {
                                    to: {
                                        type: "box"
                                    }
                                },
                                dashes: true
                            };
                        })
                    );

                    continue;
                }

                items.push(
                    ...values.map((v: any) => ({
                        from: scopeName.args.join("-"),
                        to: v.args.join("-"),
                        label: labelName
                    }))
                );
            }

            return items;
        }),
        ...(network as any).args[0].map((x: any) => {
            const [scopeName, scopeValue] = x.args;
            if (isScopeNode(scopeName, scopeValue)) return [];

            const items = [];

            for (const reference of findScopeReferences(scopeValue)) {
                items.push({
                    from: scopeName.args.join("-"),
                    to: reference,
                    label: "references",
                    dashes: true
                });
            }

            return items;
        })
    );

    const graph = {
        nodes,
        edges
    };

    let options: any;
    if (mode === "levels") {
        options = {
            layout: {
                hierarchical: {
                    direction: "UD",
                    sortMethod: "directed",
                    nodeSpacing: 300
                }
            },
            physics: false,
            edges: {
                color: "#000000"
            },
            height,
            width
        };
    } else if (mode === "hierarchy") {
        options = {
            layout: {
                hierarchical: {
                    direction: "UD",
                    sortMethod: "directed",
                    shakeTowards: "roots",
                    nodeSpacing: 300
                }
            },
            physics: false,
            edges: {
                color: "#000000"
            },
            height,
            width
        };
    } else if (mode === "physics") {
        options = {
            edges: {
                smooth: {
                    forceDirection: "none"
                }
            },
            physics: {
                forceAtlas2Based: {
                    centralGravity: 0.001,
                    springLength: 300,
                    avoidOverlap: 1,
                    springConstant: 0.02
                },
                minVelocity: 0.75,
                solver: "forceAtlas2Based"
            },
            height,
            width
        };
    }

    // hack to ensure that a rerender takes place
    return (
        <div className="graph">
            {mode === "levels" && <Graph graph={graph} options={options} />}
            {mode === "hierarchy" && <Graph graph={graph} options={options} />}
            {mode === "physics" && <Graph graph={graph} options={options} />}
        </div>
    );
}

function PasteAtermInput({ onInput }: { onInput: (a: ATerm) => any }) {
    const [input, setInput] = useState("");

    return (
        <input
            type="text"
            value={input}
            autoFocus
            className={input.length ? "invalid" : ""}
            placeholder="Paste your ATerm here..."
            onInput={(e) => {
                const value = (e.target as any).value;
                try {
                    const term = parseAterm(value);
                    if (!isCons(term, "ScopeGraph")) throw "Not a scopegraph term.";

                    (e.target as any).blur();
                    onInput(term);
                    setInput("");
                } catch {
                    setInput(value);
                }
            }}
        />
    );
}

function Controls({ onInput, onMode }: { onInput: (a: ATerm) => any; onMode: (mode: string) => any }) {
    const [mode, setMode] = useState("hierarchy");

    const handleChange = (e: any) => {
        console.log(e.target.value);
        setMode(e.target.value);
        onMode(e.target.value);
    };

    return (
        <div className="controls">
            <h2>Controls</h2>
            <div className="title">Scopegraph Input</div>
            <PasteAtermInput onInput={onInput} />
            <div className="title">Network Layout</div>
            <label>
                <input
                    type="radio"
                    value="hierarchy"
                    name="mode"
                    checked={mode === "hierarchy"}
                    onChange={handleChange}
                />{" "}
                Hierarchical
            </label>
            <label>
                <input type="radio" value="levels" name="mode" checked={mode === "levels"} onChange={handleChange} />{" "}
                Levels
            </label>
            <label>
                <input type="radio" value="physics" name="mode" checked={mode === "physics"} onChange={handleChange} />{" "}
                Physics
            </label>
        </div>
    );
}

export default function App() {
    const [parsed, setParsed] = useState<ATerm | null>(null);
    const [mode, setMode] = useState("hierarchy");

    return (
        <div className="App">
            <Controls onInput={setParsed} onMode={setMode} />

            {parsed ? (
                <ScopeGraphNetwork network={parsed} mode={mode} />
            ) : (
                <>
                    <h2>Paste a scope graph ATerm in the top left to visualize it.</h2>
                    <p>
                        You can get a scope graph by using the <code>Show raw scope graph</code> menu item in Spoofax
                        0.14.2 and newer.
                    </p>
                </>
            )}
        </div>
    );
}
